export type Entry = { name: string; value: unknown };

export const getObjectProperties = (obj: unknown): Entry[] => {
    if (!isObject(obj)) {
        return [];
    }
    return Object.entries(obj)
        .flatMap(([name, value]) => {
            if (!isObject(value)) {
                return [{ name, value }];
            } else {
                return getObjectProperties(value).map((nested) => ({ name: name + '.' + nested.name, value: nested.value }));
            }
        })
        .sort((a, b) => a.name.localeCompare(b.name));
};

export const isObject = (value: unknown): value is object => {
    return typeof value === 'object' && value != null;
};
