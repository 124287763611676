import { Component, input } from '@angular/core';
import { Entry, getObjectProperties, isObject } from '@traas/eiv/common';

@Component({
    selector: 'eiv-datatable',
    template: ` @if (data(); as data) {
        <br />
        <p>{{ title() }}</p>
        <br />
        <ion-item-group role="group" class="responsive-columns">
            @for (prop of getObjectProperties(data); track $index) {
                @if (isObject(prop.value)) {
                    <eiv-datatable [title]="prop.name" [data]="prop.value" />
                } @else {
                    <ion-item>
                        <ion-label>{{ prop.name }}</ion-label>
                        <ion-note slot="end">{{ prop.value }}</ion-note>
                    </ion-item>
                }
            }
        </ion-item-group>
    }`,
    styles: [
        `
            @media screen and (min-width: 1081px) {
                .responsive-columns {
                    columns: 2;
                    column-gap: 20px;
                }
            }
        `,
    ],
})
export class DataTableComponent {
    title = input.required<string>();
    data = input<object | null>(null);

    getObjectProperties(data: unknown): Entry[] {
        return getObjectProperties(data);
    }

    isObject(value: unknown): value is object {
        return isObject(value);
    }
}
