import { inject, Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { RoutingService } from './routing.service';
import { EMPTY, switchMap } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { LoggingService } from '@traas/common/logging';
import { TechnicalError } from '@traas/common/models';
import { EivErrorCodes } from '../models/eiv-error-codes';

@Injectable({
    providedIn: 'root',
})
export class RedirectService {
    readonly #logger = inject(LoggingService);
    canRedirect = true;

    constructor(
        private readonly configService: ConfigService,
        private readonly routingService: RoutingService,
    ) {}

    init(): void {
        this.configService
            .$getDisplayConfiguration()
            .pipe(
                filter(() => this.canRedirect),
                switchMap((newDisplayConfiguration) => {
                    return this.routingService.redirectUsingConfig(newDisplayConfiguration);
                }),
                catchError((error: Error) => {
                    this.#logger.logError(
                        new TechnicalError('Error getting display configuration', EivErrorCodes.Initialization.InvalidConfiguration, error),
                    );
                    return EMPTY;
                }),
            )
            .subscribe();
    }
}
