import { inject, Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { DATA_SERVICE_TOKEN } from './data.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { DisplayConfiguration } from '../models/display-configuration.model';
import { SYNTHESE_CALL_TIMEOUT_MS } from '../constants/configuration-polling.constants';
import { LoggingService } from '@traas/common/logging';
import { TechnicalError } from '@traas/common/models';
import { EivErrorCodes } from '../models/eiv-error-codes';

export interface ManifestJson {
    commit: string;
    version: string;
}

export type EndpointStatus = 'TIMEOUT' | 'UP' | 'DOWN';

@Injectable({
    providedIn: 'root',
})
export class VitalsService {
    readonly #configService = inject(ConfigService);
    readonly #dataService = inject(DATA_SERVICE_TOKEN);
    readonly #httpClient = inject(HttpClient);
    readonly #logger = inject(LoggingService);

    async getManifestJson(): Promise<ManifestJson> {
        const MANIFEST_JSON_PATH = '../manifest.json';
        try {
            return await firstValueFrom(this.#httpClient.get<ManifestJson>(MANIFEST_JSON_PATH, { responseType: 'json' }));
        } catch (error) {
            this.#logger.logError(new TechnicalError('Error getting manifest.json', EivErrorCodes.Vitals.ManifestFetch, error));
            return {
                version: 'error',
                commit: 'error',
            };
        }
    }

    getDisplayConfig(): Promise<DisplayConfiguration> {
        return firstValueFrom(this.#configService.$getDisplayConfiguration());
    }

    getDisplayConfigEndpointStatus(): Promise<EndpointStatus> {
        return this.#getEndpointStatus(this.getDisplayConfig());
    }

    getSyntheseDepartureEndpointStatus(): Promise<EndpointStatus> {
        return this.#getEndpointStatus(firstValueFrom(this.#dataService.$getSyntheseDepartures(this.#configService.serviceUrl)));
    }

    getSyntheseDisruptionMessageEndpointStatus(): Promise<EndpointStatus> {
        return this.#getEndpointStatus(firstValueFrom(this.#dataService.$fetchDisruptionMessages(this.#configService.messagesUrl)));
    }

    async #getEndpointStatus<T>(
        fetchDataPromise: Promise<T>,
        isValid: (data: T | void) => boolean = (data): boolean => !!data,
    ): Promise<EndpointStatus> {
        const TIMEOUT_ERROR = 'timeout';
        try {
            const timeout = new Promise<void>((_, reject) => setTimeout(() => reject(TIMEOUT_ERROR), SYNTHESE_CALL_TIMEOUT_MS));
            const response = await Promise.race([fetchDataPromise, timeout]);
            return isValid(response) ? 'UP' : 'DOWN';
        } catch (err) {
            if (err === TIMEOUT_ERROR) {
                return 'TIMEOUT';
            }
            this.#logger.logError(new TechnicalError('Error getting manifest.json', EivErrorCodes.Vitals.SyntheseEndpointDown, err));
            return 'DOWN';
        }
    }
}
