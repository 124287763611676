import { Injectable } from '@angular/core';
import { HEIGHT_OF_HEADER_IN_PX, SIZE_OF_EXPANDED_DEPARTURE } from '../constants/screen-layout-configuration.contants';
import { DisplayConfiguration } from '../models/display-configuration.model';
import { DisruptionMessageLevelEnum } from '../enums/disruption-message-level.enum';

@Injectable({
    providedIn: 'root',
})
export class GridService {
    /**
     * Generates a CSS grid template string for the rows in a display grid.
     *
     * The grid can contain a map, a header, and departures that can be either expanded or folded. The number of rows
     * and their heights are determined by the `DisplayConfiguration` object.
     *
     *  example: 6 map, 1 expanded, 15 total
     * '6fr 160px 6fr 1fr 1fr 1fr' -> 6 fr for map, 160px for header, 6fr for expanded, 3 x 1fr for folded departures
     *
     * @param {DisplayConfiguration} config - The configuration object for the display grid.
     * @return {string} A CSS grid template string that specifies the number of rows and the heights of each row in the display grid.
     *
     * @typedef {Object} DisplayConfiguration
     * @property {number} mapNumberOfRows - The number of rows to allocate for the map in the display grid.
     * @property {number} numberOfOpenRows - The number of expanded departure rows in the display grid.
     * @property {number} totalNumberOfRows - The total number of rows in the display grid.
     */
    createGridTemplateRows(config: DisplayConfiguration): string {
        let result = '';
        // map
        if (config.mapNumberOfRows > 0) {
            result += `minmax(0, ${config.mapNumberOfRows}fr) `;
        }

        // header
        result += ` ${HEIGHT_OF_HEADER_IN_PX}px `;

        let rest = config.totalNumberOfRows - config.mapNumberOfRows - 1;

        if (config.numberOfOpenRows > 0) {
            result += `repeat(${config.numberOfOpenRows}, minmax(0, 1fr) minmax(min-content, 2fr)) `;
            rest = rest - config.numberOfOpenRows * SIZE_OF_EXPANDED_DEPARTURE;
        }
        result += rest > 0 ? `repeat(${rest}, minmax(0, 1fr))` : '';

        return result;
    }

    /**
     * Will return the grid-row css position of the departure list's header
     */
    getGridHeaderRowNumber(config: DisplayConfiguration): number {
        const FIRST_ROW = 1;
        const SECOND_ROW = 2;

        const hasMapInGrid = config.mapNumberOfRows > 0;
        if (hasMapInGrid) {
            return SECOND_ROW;
        }

        return FIRST_ROW;
    }

    getDisruptionMessageHeightInPixels(priorityLevel: DisruptionMessageLevelEnum, config: DisplayConfiguration): number {
        switch (priorityLevel) {
            case DisruptionMessageLevelEnum.Complementary:
                return config.heightOfComplementaryMessageInPixels;
            case DisruptionMessageLevelEnum.Priority:
                return config.heightOfPriorityMessageInPixels;
            case DisruptionMessageLevelEnum.Alternate:
            case DisruptionMessageLevelEnum.Global:
                return window.innerHeight;
            default:
                throw new Error(`Impossible to get height in pixels : unknown disruption message level : ${priorityLevel}`);
        }
    }
}
