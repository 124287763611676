import { HttpClient } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { isNotNullOrUndefined, ONE_SECOND_IN_MS } from '@traas/common/utils';
import * as _ from 'lodash';
import { BehaviorSubject, first, firstValueFrom, Observable, of, race, Subscription, timer } from 'rxjs';
import { catchError, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { DEFAULT_CONFIGURATION_POLLING_DELAY_IN_MS, SYNTHESE_CALL_TIMEOUT_MS } from '../constants/configuration-polling.constants';
import { AppConfiguration } from '../models/app-configuration.models';
import { DisplayConfiguration, SyntheseDisplayConfiguration } from '../models/display-configuration.model';
import * as displayConfigValidator from './display-config.validator';
import { ConfigErrorCode, validateDisplayConfig } from './display-config.validator';
import { ENVIRONMENT_SERVICE_TOKEN, EnvironmentService } from './environment.service';
import { FakeDeparturesConfig } from './fake-departures-factory.utils';
import { HEADER_ROW, SIZE_OF_EXPANDED_DEPARTURE } from '../constants/screen-layout-configuration.contants';
import { NavigationExtras } from '@angular/router';
import { ErrorRedirectService } from './error-redirect.service';
import { LoggingService } from '@traas/common/logging';
import { TechnicalError } from '@traas/common/models';
import { EivErrorCodes } from '../models/eiv-error-codes';

const DEFAULT_DISRUPTION_BACKGROUND_COLOR = '#EB0000';
const BACKEND_PARAMETER_NAME = 'backendUrl';
const MAC_ADDRESS_PARAMETER_NAME = 'macAddress';
const LOCAL_CONFIG_URL = 'assets/config.json';

/**
 * todo Split this class into a mock-config.service.ts and config.service.ts
 */
@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    readonly #errorRedirectService = inject(ErrorRedirectService);
    readonly #logger = inject(LoggingService);
    readonly #$displayConfiguration = new BehaviorSubject<DisplayConfiguration | undefined>(undefined);
    readonly #$configurationPollingDelayInMs = new BehaviorSubject(DEFAULT_CONFIGURATION_POLLING_DELAY_IN_MS);
    readonly #$syntheseDisplayConfigOrNull: Observable<SyntheseDisplayConfiguration | null>;
    readonly #$shouldRedirectOnFirstError: Observable<boolean>;

    get mockDisplayConfigFilePath(): string | null {
        return localStorage.getItem('mockDisplayConfigFilePath') ?? null;
    }

    set mockDisplayConfigFilePath(value: string | null) {
        localStorage.setItem('mockDisplayConfigFilePath', value ?? '');
    }

    get fakeDeparturesConfig(): FakeDeparturesConfig | null {
        const dataInStorage = localStorage.getItem('fakeDeparturesConfig');
        if (!dataInStorage) {
            return null;
        }
        return JSON.parse(dataInStorage);
    }

    set fakeDeparturesConfig(value: FakeDeparturesConfig | null) {
        if (value) {
            localStorage.setItem('fakeDeparturesConfig', JSON.stringify(value));
        } else {
            localStorage.removeItem('fakeDeparturesConfig');
        }
    }

    constructor(
        @Inject(ENVIRONMENT_SERVICE_TOKEN)
        private readonly environmentService: EnvironmentService,
        private readonly http: HttpClient,
    ) {
        this.#$syntheseDisplayConfigOrNull = this.#$buildSyntheseDisplayConfigOrNull().pipe(shareReplay(1));
        this.#$shouldRedirectOnFirstError = this.#$buildShouldRedirectOnFirstError();
    }

    #appConfiguration!: AppConfiguration;

    get sentryEnvironment(): string {
        return this.#appConfiguration.sentryEnvironment;
    }

    get macAddress(): string {
        return this.#appConfiguration.macAddress;
    }

    get serviceUrl(): string {
        const { serviceUrlTemplate, macAddress, backendUrl } = this.#appConfiguration;
        return serviceUrlTemplate.replace(`:${BACKEND_PARAMETER_NAME}`, backendUrl).replace(`:${MAC_ADDRESS_PARAMETER_NAME}`, macAddress);
    }

    get messagesUrl(): string {
        const { messagesUrlTemplate, macAddress, backendUrl } = this.#appConfiguration;
        return messagesUrlTemplate.replace(`:${BACKEND_PARAMETER_NAME}`, backendUrl).replace(`:${MAC_ADDRESS_PARAMETER_NAME}`, macAddress);
    }

    get displayConfigUrl(): string {
        if (this.environmentService.getUseFakeData()) {
            const displayConfigUrl = this.mockDisplayConfigFilePath;
            if (!displayConfigUrl) {
                throw new Error(`Don't have have mockDisplayConfigFilePath`);
            }
            return displayConfigUrl;
        }
        const { displayConfigUrlTemplate, macAddress, backendUrl } = this.#appConfiguration;
        return displayConfigUrlTemplate
            .replace(`:${BACKEND_PARAMETER_NAME}`, backendUrl)
            .replace(`:${MAC_ADDRESS_PARAMETER_NAME}`, macAddress);
    }

    getDisplayConfiguration(): DisplayConfiguration | undefined {
        return this.#$displayConfiguration.getValue();
    }

    $getDisplayConfiguration(): Observable<DisplayConfiguration> {
        return this.#$displayConfiguration.pipe(filter(isNotNullOrUndefined));
    }

    $getDisruptionBackgroundColor(): Observable<string> {
        return this.$getDisplayConfiguration().pipe(
            map((config) => {
                if (config && config.overrideDisruptionBackgroundColor?.length) {
                    return config.overrideDisruptionBackgroundColor;
                }
                return DEFAULT_DISRUPTION_BACKGROUND_COLOR;
            }),
        );
    }

    $getValidDisplayConfiguration(): Observable<DisplayConfiguration> {
        return this.$getDisplayConfiguration().pipe(filter((displayConfig) => validateDisplayConfig(displayConfig).isValid));
    }

    setDisplayConfiguration(value: DisplayConfiguration): void {
        this.#$displayConfiguration.next(value);
    }

    async initAppConfiguration(): Promise<void> {
        this.#appConfiguration = await firstValueFrom(this.http.get<AppConfiguration>(LOCAL_CONFIG_URL));

        // Configuration received from URL has priority on config.json file.
        const searchParams = this.getSearchParamsFromUrl();
        if (!searchParams) {
            return;
        }

        const macAddress = this.getMacAddressFromSearchParams(searchParams);
        if (macAddress) {
            this.#appConfiguration.macAddress = macAddress;
        }
        const backendUrl = this.getBackendUrlFromSearchParams(searchParams);
        if (backendUrl) {
            this.#appConfiguration.backendUrl = backendUrl;
        }
    }

    setDebug(mockDisplayConfigFilePath: string, fakeDeparturesConfig: FakeDeparturesConfig): void {
        this.mockDisplayConfigFilePath = mockDisplayConfigFilePath;
        this.fakeDeparturesConfig = fakeDeparturesConfig;
    }

    clearMockedConfigProperties(): void {
        this.mockDisplayConfigFilePath = null;
        this.fakeDeparturesConfig = null;
    }

    hasMockedConfigPropertiesEmpty(): boolean {
        return !this.mockDisplayConfigFilePath || !this.fakeDeparturesConfig;
    }

    subscribeToDisplayConfigurationPolling(): Subscription {
        this.#$shouldRedirectOnFirstError.pipe(filter((shouldRedirectOnFirstError) => shouldRedirectOnFirstError)).subscribe({
            next: () => {
                const navigationExtras = this.createNavigationExtras();
                void this.handleConfigError(navigationExtras, ConfigErrorCode.NoDisplayConfiguration);
            },
        });

        return this.#$startPollingDisplayConfiguration().subscribe({
            next: (newDisplayConfig) => {
                const validateDisplayConfigResult = displayConfigValidator.validateDisplayConfig(newDisplayConfig);
                if (validateDisplayConfigResult.isValid) {
                    this.setDisplayConfiguration(newDisplayConfig);
                    this.#$configurationPollingDelayInMs.next(newDisplayConfig.refreshDelayInSec * ONE_SECOND_IN_MS);
                    if (this.environmentService.getUseFakeData()) {
                        console.log('Display configuration created: ', newDisplayConfig);
                    }
                } else {
                    const navigationExtras = this.createNavigationExtras();
                    void this.handleConfigError(navigationExtras, validateDisplayConfigResult.errorCode, newDisplayConfig);
                }
            },
        });
    }

    async handleConfigError(
        navigationExtras: NavigationExtras,
        errorCode: ConfigErrorCode | undefined,
        displayConfiguration?: DisplayConfiguration,
    ): Promise<boolean> {
        this.#logger.logError(
            new TechnicalError('configuration error', EivErrorCodes.Configuration.InvalidConfiguration, undefined, {
                validationErrorCode: errorCode,
                displayConfiguration,
            }),
        );
        return this.#errorRedirectService.redirectToErrorPage(navigationExtras, errorCode);
    }

    getSearchParamsFromUrl(): URLSearchParams {
        return new URLSearchParams(window.location.search);
    }

    getBackendUrlFromSearchParams(searchParams: URLSearchParams): string | null {
        return searchParams.get(BACKEND_PARAMETER_NAME);
    }

    getMacAddressFromSearchParams(searchParams: URLSearchParams): string | null {
        return searchParams.get(MAC_ADDRESS_PARAMETER_NAME);
    }

    createNavigationExtras(): NavigationExtras {
        const searchParams = this.getSearchParamsFromUrl();
        if (!searchParams) {
            return {};
        }

        const backendUrl = this.getBackendUrlFromSearchParams(searchParams);
        const macAddress = this.getMacAddressFromSearchParams(searchParams);
        return { queryParams: { backendUrl, macAddress } };
    }

    #countNbDisplayedDepartures(syntheseDisplayConfiguration: SyntheseDisplayConfiguration): number {
        return (
            syntheseDisplayConfiguration.numberOfOpenRows +
            syntheseDisplayConfiguration.totalNumberOfRows -
            HEADER_ROW -
            syntheseDisplayConfiguration.mapNumberOfRows -
            syntheseDisplayConfiguration.numberOfOpenRows * SIZE_OF_EXPANDED_DEPARTURE
        );
    }

    /**
     * Starts the process of polling for display configuration data.
     * Polling is initiated by creating a timer that triggers the display configuration data retrieval every
     * `DEFAULT_CONFIGURATION_POLLING_DELAY_IN_MS` milliseconds.
     * The new configuration data is filtered to check if it is different from the current configuration data.
     *
     * @returns {Observable<SyntheseDisplayConfiguration | null>} - Returns an Observable that emits the updated SyntheseDisplayConfiguration data or null.
     */
    #$buildSyntheseDisplayConfigOrNull(): Observable<SyntheseDisplayConfiguration | null> {
        return this.#$configurationPollingDelayInMs.pipe(
            switchMap((configurationPollingDelayInMs) => {
                return timer(0, configurationPollingDelayInMs).pipe(
                    switchMap(() => {
                        return this.#fetchConfigOrTimeout(this.displayConfigUrl).pipe(
                            catchError((error) => {
                                this.#logger.logError(
                                    new TechnicalError(
                                        'Error refreshing display configuration from Synthese',
                                        EivErrorCodes.Configuration.FetchConfiguration,
                                        error,
                                    ),
                                );
                                return of(null);
                            }),
                        );
                    }),
                );
            }),
        );
    }

    #fetchConfigOrTimeout(displayConfigUrl: string): Observable<SyntheseDisplayConfiguration> {
        return race(
            this.http.get<SyntheseDisplayConfiguration>(displayConfigUrl),
            timer(SYNTHESE_CALL_TIMEOUT_MS).pipe(
                map(() => {
                    throw new Error('TIMEOUT');
                }),
            ),
        );
    }

    #$startPollingDisplayConfiguration(): Observable<DisplayConfiguration> {
        return this.#$syntheseDisplayConfigOrNull.pipe(
            filter((syntheseConfig): syntheseConfig is SyntheseDisplayConfiguration => !!syntheseConfig),
            map<SyntheseDisplayConfiguration, DisplayConfiguration>((syntheseDisplayConfiguration) => {
                return this.#mapSyntheseDisplayConfigurationToDisplayConfiguration(syntheseDisplayConfiguration);
            }),
            filter((newConfig) => {
                const existingConfig = this.getDisplayConfiguration();
                if (!existingConfig) {
                    return true;
                }
                return !_.isEqual(newConfig, existingConfig);
            }),
        );
    }

    #$buildShouldRedirectOnFirstError(): Observable<boolean> {
        return this.#$syntheseDisplayConfigOrNull.pipe(
            first(),
            map((value) => value === null),
            startWith(false),
        );
    }

    #mapSyntheseDisplayConfigurationToDisplayConfiguration(
        syntheseDisplayConfiguration: SyntheseDisplayConfiguration,
    ): DisplayConfiguration {
        return {
            displayClock: syntheseDisplayConfiguration.displayClock,
            displayDurationSeveralMessages: syntheseDisplayConfiguration.displayDurationSeveralMessages,
            displayServiceNumber: syntheseDisplayConfiguration.displayServiceNumber,
            displayTrack: syntheseDisplayConfiguration.displayTrack,
            heightOfComplementaryMessageInPixels: syntheseDisplayConfiguration.heightOfComplementaryMessageInPixels,
            heightOfPriorityMessageInPixels: syntheseDisplayConfiguration.heightOfPriorityMessageInPixels,
            hideMap: syntheseDisplayConfiguration.hideMap,
            mapCenterCoordinates: syntheseDisplayConfiguration.mapCenterCoordinates,
            mapNumberOfRows: syntheseDisplayConfiguration.mapNumberOfRows,
            mapZoomLevel: syntheseDisplayConfiguration.mapZoomLevel,
            name: syntheseDisplayConfiguration.name,
            nbDisplayedDepartures: this.#countNbDisplayedDepartures(syntheseDisplayConfiguration),
            numberOfOpenRows: syntheseDisplayConfiguration.numberOfOpenRows,
            overrideDisruptionBackgroundColor: syntheseDisplayConfiguration.overrideDisruptionBackgroundColor,
            refreshDelayInSec: syntheseDisplayConfiguration.refreshDelayInSec,
            terminalCoordinates: syntheseDisplayConfiguration.terminalCoordinates,
            totalNumberOfRows: syntheseDisplayConfiguration.totalNumberOfRows,
            mapBearing: syntheseDisplayConfiguration.mapBearing,
        };
    }
}
