export interface GetDepartureResponse {
    departures: Departure[];
}

// eslint-disable-next-line no-shadow
export enum LabelIconCodesEnum {
    FirstStopOnDemand = 'ReservationCompulsory',
    HasNextStopOnDemand = 'NextStopReservationCompulsory',
    HasNextStopOptional = 'NextStopOptional',
}

export interface Departure {
    direction: string;
    plannedDepartureTime: string;
    departureDelay: string;
    isCanceled: boolean;
    label: string;
    labelIconCode: LabelIconCodesEnum | null;
    line: {
        name: string;
        backgroundColor: string;
        textColor: string;
    };
    track: string;
    stop: {
        name: string;
        isOptional: boolean;
        coordinates: string;
        messages: {
            id: string;
            title: string;
            content: string;
            displayDuration: number;
        }[];
    };
    transport: string;
    hasBookingRequirements: boolean;
    thermo: Thermometer[];
    isGone: boolean;
}

export interface Thermometer {
    stopName: string;
    hasBookingRequirements: boolean;
    transferLines: LineConnection[];
}

export interface LineConnection {
    backgroundColor: string;
    textColor: string;
    direction: string;
    transport: string;
    name: string;
}
