import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'eiv-centered-spinner',
    templateUrl: './centered-spinner.component.html',
    styleUrls: ['./centered-spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CenteredSpinnerComponent {
    @Input() overridenStyle = '';
}
