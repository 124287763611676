import { inject, Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { DisplayConfiguration } from '../models/display-configuration.model';
import { RoutesNames } from './routing.service';
import { ConfigErrorCode } from './display-config.validator';

@Injectable({
    providedIn: 'root',
})
export class ErrorRedirectService {
    readonly #router = inject(Router);

    redirectToErrorPage(navigationExtras: NavigationExtras, errorCode: ConfigErrorCode | undefined): Promise<boolean> {
        const extras: NavigationExtras = {
            ...navigationExtras,
            state: { errorCode },
        };
        return this.#router.navigate([RoutesNames.ConfigurationError], extras);
    }
}
