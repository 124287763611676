import * as Sentry from '@sentry/angular-ivy';
import * as SentryAngular from '@sentry/angular-ivy';
import { SentryBreadcrumbsUtils } from '@traas/common/logging';

export function initSentry(
    isProd: boolean,
    dsn: string,
    sentryRelease: string,
    environment: string | undefined,
    currentCommitHash: string,
): void {
    const dropBreadcrumb = (): null => null;

    Sentry.init({
        dsn: dsn,
        release: sentryRelease,
        environment: environment,
        dist: currentCommitHash,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: isProd ? 0.1 : 1.0,
        transport: SentryAngular.makeBrowserOfflineTransport(SentryAngular.makeFetchTransport) as any,
        beforeBreadcrumb(breadcrumb, hint) {
            if (SentryBreadcrumbsUtils.isSvgAssetsLoadingBreadcrumb(breadcrumb)) {
                return dropBreadcrumb();
            }
            return breadcrumb;
        },
    });
    Sentry.addGlobalEventProcessor(function (event, _) {
        if (!event.exception || !event.exception.values?.length) {
            return event;
        }
        event.exception.values.forEach((exception) => {
            if (exception.mechanism && exception.type === 'FatalError') {
                exception.mechanism.handled = false;
            }
        });
        return event;
    });
}
