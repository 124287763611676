import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigErrorCode } from 'libs/eiv/common/src/lib/services/display-config.validator';

@Component({
    selector: 'eiv-configuration-error-page',
    templateUrl: './configuration-error-page.component.html',
    styleUrls: ['./configuration-error-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationErrorPageComponent {
    readonly #router = inject(Router);
    readonly errorCode!: ConfigErrorCode;
    readonly errorDetail!: string;

    constructor() {
        this.errorCode = this.#router.getCurrentNavigation()?.extras?.state?.['errorCode'] as ConfigErrorCode;
        this.errorDetail = ConfigErrorCode[this.errorCode] ?? '';
    }
}
