import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ScreenOrientationService {
    orientation!: 'portrait' | 'landscape';

    readonly #ORIENTATION_QUERY = '(orientation: portrait)';

    constructor() {
        this.orientation = window.matchMedia(this.#ORIENTATION_QUERY).matches ? 'portrait' : 'landscape';

        window.matchMedia(this.#ORIENTATION_QUERY).addEventListener('change', (e) => {
            this.orientation = e.matches ? 'portrait' : 'landscape';
        });
    }
}
