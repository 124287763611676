export const EivErrorCodes = {
    DepartureList: {
        FetchDepartureSyntheseEmptyResponse: 'departureList.fetchDepartureSyntheseEmptyResponse',
        FetchDepartureNetworkError: 'departureList.fetchDepartureNetworkError',
    },
    DisruptionMessages: {
        SyntheseEmptyResponse: 'disruptionMessages.syntheseEmptyResponse',
        NetworkError: 'disruptionMessages.networkError',
        NotSamePriorityLevel: 'disruptionMessages.notSamePriorityLevel',
        NoAlternatingMessageDelay: 'disruptionMessages.noAlternatingMessageDelay',
        NoMessageDisplayDuration: 'disruptionMessages.noMessageDisplayDuration',
    },
    Vitals: {
        ManifestFetch: 'manifestJson.manifestFetch',
        SyntheseEndpointDown: 'manifestJson.syntheseEndpointDown',
        LogVitals: 'monitoringService.logVitals',
    },
    Initialization: {
        BadDisplayConfiguration: 'initialization.badDisplayConfiguration',
        InvalidConfiguration: 'initialization.invalidConfiguration',
    },
    Configuration: {
        InvalidConfiguration: 'configuration.invalidConfiguration',
        FetchConfiguration: 'configuration.fetchConfiguration',
    },
};
