import { parseSyntheseDatetime, parseSyntheseDelayAsMs } from '@traas/common/utils';
import { CANCELED_DEPARTURE_COLOR } from '../constants/screen-layout-configuration.contants';
import { Departure } from '../models/data.model';
import { addMilliseconds } from 'date-fns';

export const getDepartureRealTime = (syntheseScheduledDateTime: string, syntheseDelay: string): Date =>
    addMilliseconds(parseSyntheseDatetime(syntheseScheduledDateTime), parseSyntheseDelayAsMs(syntheseDelay));

export const getThermometerColor = (departure: Departure): string => {
    const thermometerColor = departure.line.backgroundColor;
    const hasNoBackgroundColor = !thermometerColor;

    if (hasNoBackgroundColor || departure.isCanceled || departure.isGone) {
        return CANCELED_DEPARTURE_COLOR;
    }
    return thermometerColor;
};

export const getLineIconBackgroundColor = (departure: Departure): string => {
    const lineIconBackgroundColor = departure.line.backgroundColor;
    const hasNoBackgroundColor = !lineIconBackgroundColor;

    if (hasNoBackgroundColor || departure.isGone) {
        return CANCELED_DEPARTURE_COLOR;
    }
    return lineIconBackgroundColor;
};
