import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeFrCH from '@angular/common/locales/fr-CH';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule } from '@ionic/angular';
import { AppComponent } from './app.component';
import {
    ConfigService,
    DATA_SERVICE_TOKEN,
    DataService,
    EivCommonModule,
    EivGlobalErrorHandler,
    ENVIRONMENT_SERVICE_TOKEN,
    EnvironmentService,
    MockDataService,
    SyntheseDataService,
} from '@traas/eiv/common';
import { AppRoutingModule } from './app-routing.module';
import { LoadingPageComponent } from './pages/loading-page/loading-page.component';
import { ConfigurationErrorPageComponent } from './pages/configuration-error-page/configuration-error-page.component';
import { DebugPickDisplayPageComponent } from './pages/debug-pick-display-page/debug-pick-display-page.component';
import { EnvironmentServiceImpl } from '../services/environment.service.impl';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { HealthCheckPageComponent } from './pages/health-check-page/health-check-page.component';
import { DataTableComponent } from './pages/health-check-page/datatable.component';
import { LOG_REMOTE, LoggingService } from '@traas/common/logging';
import { VehicleIconModule } from '@traas/common/components';

registerLocaleData(localeFrCH);

@NgModule({
    declarations: [
        AppComponent,
        LoadingPageComponent,
        ConfigurationErrorPageComponent,
        DebugPickDisplayPageComponent,
        HealthCheckPageComponent,
        DataTableComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        IonicModule.forRoot(),
        VehicleIconModule,
        EivCommonModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: EivGlobalErrorHandler,
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        { provide: LOCALE_ID, useValue: 'fr-CH' },
        {
            provide: APP_INITIALIZER,
            useFactory: (config: ConfigService) => async (): Promise<void> => {
                await config.initAppConfiguration();
                Sentry.configureScope((scope) => {
                    console.log('sentry environment:', config.sentryEnvironment);
                    scope.setTag('environment', config.sentryEnvironment);
                    scope.setTag('macAddress', config.macAddress);
                });
            },
            deps: [ConfigService],
            multi: true,
        },
        {
            provide: ENVIRONMENT_SERVICE_TOKEN,
            useClass: EnvironmentServiceImpl,
        },
        {
            provide: DATA_SERVICE_TOKEN,
            useFactory: (envService: EnvironmentService, configService: ConfigService, http: HttpClient): DataService =>
                envService.getUseFakeData() ? new MockDataService(configService, http) : new SyntheseDataService(http),
            deps: [ENVIRONMENT_SERVICE_TOKEN, ConfigService, HttpClient],
        },
        { provide: LOG_REMOTE, useValue: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
