import { addMilliseconds, differenceInMilliseconds, isBefore, parse } from 'date-fns';

export const DEFAULT_SYNTHESE_DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export const parseSyntheseDatetime = (date: string, format = DEFAULT_SYNTHESE_DATETIME_FORMAT): Date => {
    return parse(date, format, new Date());
};

/**
 * Parses a Synthese delay and returns it in MS (>0 = delay, <0 = early)
 * @param duration format : hh:mm:ss or -hh:mm:ss
 */
export const parseSyntheseDelayAsMs = (duration: string): number => {
    if (!duration) {
        return 0;
    }
    const isNegative = duration[0] === '-';
    if (isNegative) {
        duration = duration.substring(1);
    }
    const [h, m, s] = duration.split(':');

    const durationAsMs = parseInt(h) * 60 * 60 * 1000 + parseInt(m) * 60 * 1000 + parseInt(s) * 1000;

    return (isNegative ? -1 : 1) * durationAsMs;
};

/**
 * Returns the display string for a delay from Synthese
 */
export const formatDelay = (delayInMs: number): string => {
    const busInOnTime = !delayInMs;
    if (busInOnTime) {
        return '';
    }

    const delayOrEarlySymbol = delayInMs < 0 ? '-' : '+';
    const absRoundedDelayInMinutes = Math.floor(Math.abs(delayInMs) / 1000 / 60);

    // that case is not supposed to happen, if it does, we display nothing
    const busIsMoreThan10HoursEarly = delayInMs < 0 && absRoundedDelayInMinutes > 600;
    if (busIsMoreThan10HoursEarly) {
        return '';
    }

    const busIsLessThan60SecondsLateOrEarly = absRoundedDelayInMinutes === 0;
    if (busIsLessThan60SecondsLateOrEarly) {
        return '';
    }

    const busIsMoreThan10HoursLate = absRoundedDelayInMinutes > 600;
    if (busIsMoreThan10HoursLate) {
        return '>10h';
    }

    const busIsLessThan60MinutesLateOrEarly = absRoundedDelayInMinutes < 60;
    if (busIsLessThan60MinutesLateOrEarly) {
        return `${delayOrEarlySymbol}${absRoundedDelayInMinutes}'`;
    }

    // last case : we display +3h42 or -2h54
    const hours = Math.floor(absRoundedDelayInMinutes / 60);
    const minutes = absRoundedDelayInMinutes - hours * 60;

    if (minutes === 0) {
        return `${delayOrEarlySymbol}${hours}h`;
    }

    return `${delayOrEarlySymbol}${hours}h${minutes.toString().padStart(2, '0')}`;
};

export const isOutdated = (syntheseScheduledDateTime: string, syntheseDelay: string): boolean => {
    const now = new Date();
    const syntheseScheduledDateTimeWithDelay = addMilliseconds(
        parseSyntheseDatetime(syntheseScheduledDateTime),
        parseSyntheseDelayAsMs(syntheseDelay),
    );
    return isBefore(syntheseScheduledDateTimeWithDelay, now);
};

export const differenceInMillisecondsFromNow = (date: Date): number => {
    return differenceInMilliseconds(date, new Date());
};
