import { Injectable } from '@angular/core';
import { EnvironmentService } from '@traas/eiv/common';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentServiceImpl implements EnvironmentService {
    getUseFakeData(): boolean {
        return environment.useFakeData;
    }

    getMapTilerUrl(): string {
        return environment.mapTilerUrl;
    }
}
