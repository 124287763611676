import { inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { interval, Observable, of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { DisruptionMessageLevelEnum } from '../enums/disruption-message-level.enum';
import { DisplayConfiguration } from '../models/display-configuration.model';
import { Message } from '../models/message.model';
import { ConfigService } from './config.service';
import { DATA_SERVICE_TOKEN } from './data.service';
import { ONE_SECOND_IN_MS } from '@traas/common/utils';
import { LoggingService } from '@traas/common/logging';
import { TechnicalError } from '@traas/common/models';
import { EivErrorCodes } from '../models/eiv-error-codes';

@Injectable({
    providedIn: 'root',
})
export class DisruptionMessageService {
    #dataService = inject(DATA_SERVICE_TOKEN);
    #configService = inject(ConfigService);
    #logger = inject(LoggingService);

    /**
     * Returns an Observable of alternating messages or `null` based on the `DisplayConfiguration` provided.
     * The sequence will be recreated if $config passed as parameter change.
     *
     * @param {Observable<DisplayConfiguration>} $config - Observable of `DisplayConfiguration`
     * @returns {Observable<Message | null>} - Observable of alternating messages or `null`
     */
    $getAlternatingMessageSequence($config: Observable<DisplayConfiguration>): Observable<Message | null> {
        return $config.pipe(
            switchMap((displayConfig) =>
                this.#$pollMessages(displayConfig.refreshDelayInSec * ONE_SECOND_IN_MS, this.#configService.messagesUrl).pipe(
                    switchMap((messages) =>
                        this.#$createAlternatingMessageSequence(messages, displayConfig.displayDurationSeveralMessages * ONE_SECOND_IN_MS),
                    ),
                    catchError((err) => {
                        console.error(err);
                        return of(null);
                    }),
                ),
            ),
        );
    }

    /**
     * Will emit only if value changed
     */
    #$pollMessages(refreshDelayInMs: number, messageUrl: string): Observable<Message[]> {
        return timer(0, refreshDelayInMs).pipe(
            switchMap(() => this.#dataService.$fetchDisruptionMessages(messageUrl)),
            distinctUntilChanged((a, b) => _.isEqual(a, b)),
        );
    }

    /**
     * Émet les messages dans une séquence avec un délai spécifié, alternant entre chaque message et null.
     *
     * Si il n'y a qu'un seul message avec un niveau autre que "alternate" dans la séquence, il n'émettra que ce message.
     *
     * Si il n'y a qu'un seul message avec le niveau "alternate" dans la séquence, il alternera entre
     * les messages et null avec le délai spécifié sur le message.
     *
     * Si il y a plusieurs messages avec le niveau "alternate" dans la séquence, il alternera entre
     * chaque message[i]/null/message[i+1]/null etc avec le délai spécifié en paramètre.
     *
     * Si il y a plusieurs messages de niveaux différents, une erreur est lancée.
     * Si il n'y a pas de messages, une erreur est lancée.
     * Si il y a plus de messages de mêmes niveaux, sans paramètre alternatingMessageDelay (ou 0), une erreur est lancée.
     * Si il y a un message de niveau "alterner", sans durée d'affichage sur celui-ci (ou 0), une erreur est lancée.
     *
     * La séquence sera recréée à chaque appel de cette méthode.
     *
     * exemple avec plusieurs messages de même niveau : MessageA - null - MessageB - null - MessageA - null…
     * exemple avec un message de niveau "alternate" : Message - null - Message - null - Message - null…
     *
     * @returns {Observable} An observable emitting the message sequence.
     */
    #$createAlternatingMessageSequence(messages: Message[], alternatingMessageDelayInMs: number): Observable<Message | null> {
        if (!messages.length) {
            return of(null);
        }

        const onlyOneMessage = messages.length === 1;
        const onlyOneAlternateMessage = onlyOneMessage && messages[0].priorityLevel === DisruptionMessageLevelEnum.Alternate;
        if (onlyOneAlternateMessage) {
            return this.#$createSequenceForOneAlternateMessage(messages);
        }

        if (onlyOneMessage) {
            return of(messages[0]);
        }

        // ensure that all messages have same priorityLevel
        const level = messages[0].priorityLevel;
        const allMessagesHaveSameLevel = messages.every((m) => m.priorityLevel === level);
        if (!allMessagesHaveSameLevel) {
            this.#logger.logError(
                new TechnicalError(
                    'All messages must have the same priorityLevel.',
                    EivErrorCodes.DisruptionMessages.NotSamePriorityLevel,
                    undefined,
                    {
                        $createAlternatingMessageSequence: messages,
                    },
                ),
            );
            return of(null);
        }

        // ensure that we have an alternate delay
        if (!alternatingMessageDelayInMs || alternatingMessageDelayInMs <= 0) {
            this.#logger.logError(
                new TechnicalError(
                    'Alternating message delay must be defined and greater than 0.',
                    EivErrorCodes.DisruptionMessages.NoAlternatingMessageDelay,
                    undefined,
                    {
                        $createAlternatingMessageSequence: alternatingMessageDelayInMs,
                    },
                ),
            );
            return of(null);
        }

        // many messages with alternation
        return this.#$createSequenceForMultipleMessagesOfSameLevel(alternatingMessageDelayInMs, level, messages);
    }

    #$createSequenceForMultipleMessagesOfSameLevel(
        alternatingMessageDelay: number,
        level: DisruptionMessageLevelEnum,
        messages: Message[],
    ): Observable<Message | null> {
        return interval(alternatingMessageDelay).pipe(
            map((sequenceIndex) => {
                switch (level) {
                    case DisruptionMessageLevelEnum.Alternate: {
                        // messageA - alternatingMessageDelay ms - null - alternatingMessageDelay ms - messageB - alternatingMessageDelay ms - null - alternatingMessageDelay ms - messageA
                        const showMessage = sequenceIndex % 2 === 0;
                        if (showMessage) {
                            // sequentialNumber est forcément pair ici
                            const messageIndex = (sequenceIndex / 2) % messages.length; // Garantie que le résultat sera entre 0 et messages.length
                            return messages[messageIndex];
                        }
                        return null;
                    }
                    default: {
                        // messageA - alternatingMessageDelay ms - messageB - alternatingMessageDelay ms - messageA
                        const messageIndex = sequenceIndex % messages.length;
                        return messages[messageIndex];
                    }
                }
            }),
        );
    }

    #$createSequenceForOneAlternateMessage(messages: Message[]): Observable<Message | null> {
        const [message] = messages;
        // message - message.displayDuration ms - null - message.displayDuration ms - message - message.displayDuration ms - null
        if (!message.displayDuration || message.displayDuration <= 0) {
            this.#logger.logError(
                new TechnicalError(
                    'Message display duration must be defined and greater than 0.',
                    EivErrorCodes.DisruptionMessages.NoMessageDisplayDuration,
                    undefined,
                    {
                        $createSequenceForOneAlternateMessage: message.displayDuration,
                    },
                ),
            );

            return of(null);
        }
        return interval(message.displayDuration * ONE_SECOND_IN_MS).pipe(map((delay) => (delay % 2 === 0 ? message : null)));
    }
}
