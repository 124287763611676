/* eslint-disable sonarjs/no-duplicate-string */
import { Departure, LabelIconCodesEnum, LineConnection } from '../models/data.model';
import { addMinutes, format, isBefore } from 'date-fns';

export interface FakeDeparturesConfig {
    nbDepartures: number;
    nbDeparturesInPast: number;
    nbDeparturesCanceled: number;
    onDemand?: boolean;
}

export function createDeparturesStub(config: FakeDeparturesConfig): Departure[] {
    const { nbDepartures, nbDeparturesInPast, nbDeparturesCanceled, onDemand } = config;
    const departures: Departure[] = [];

    let currentDepartureTime = addMinutes(new Date(), nbDeparturesInPast === 0 ? 1 : -1 + nbDeparturesInPast * -2);
    for (let i = 0; i < nbDepartures; i++) {
        const isInPast = isBefore(currentDepartureTime, new Date());
        const departureData = i % 2 === 0 ? departureData0 : departureData1;
        const isCanceled = i < nbDeparturesCanceled;
        const departure: Departure = {
            direction: departureData.direction,
            plannedDepartureTime: format(currentDepartureTime, 'yyyy-MM-dd HH:mm:ss'),
            // seen with Laure : if a departure is canceled, Synthese shall not return a delay, only isCanceled:true
            departureDelay: isCanceled ? '00:00:00' : delays[i],
            isCanceled: isCanceled,
            labelIconCode: null,
            isGone: isInPast,
            label: '',
            line: {
                name: departureData.lineName,
                backgroundColor: departureData.lineBgColor,
                textColor: departureData.lineTextColor,
            },
            track: departureData.track,
            stop: {
                name: departureData.stopName,
                isOptional: false,
                messages: [
                    {
                        id: '1',
                        // put a long message, more than 250 characters, to test the overflow
                        // content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies ultrices, nisl nisl aliquam nisl, nec aliquam nisl nisl nec nisl. Null auctor, nisl eget ultricies ultrices, nisl nisl aliquam nisl, nec aliquam nisl nisl nec nisl. Null auctor, nisl eget ultricies ultrices, nisl nisl aliquam nisl, nec aliquam nisl nisl nec nisl. Null auctor, nisl eget ultricies ultrices, nisl nisl aliquam nisl, nec aliquam nisl nisl nec nisl. Null auctor, nisl eget ultricies ultrices, nisl nisl aliquam nisl, nec aliquam nisl nisl nec nisl. Null auctor, nisl eget ultricies ultrices, nisl nisl aliquam nisl, nec aliquam nisl nisl nec nisl. Null auctor, nisl eget ultricies ultrices, nisl nisl aliquam nisl, nec aliquam nisl nisl nec nisl. Null auctor, nisl eget ultricies ultrices, nisl nisl aliquam nisl, nec aliquam nisl nisl nec nisl. Null auctor, nisl eget ultricies ultrices, nisl nisl aliquam nisl, nec aliquam nisl nisl nec nisl. Null auctor',
                        content:
                            'coucou coucou coucou coucou coucou coucou coucou coucou coucou coucou coucou coucou coucou coucou coucou coucou coucou coucou coucou coucou coucou ',
                        displayDuration: 2000,
                        title: 'Title 1',
                    },
                ],
                coordinates: departureData.coordinates,
            },
            transport: departureData.transport,
            hasBookingRequirements: false,
            thermo: departureData.thermo,
        };

        if (onDemand) {
            departure.labelIconCode = LabelIconCodesEnum.FirstStopOnDemand;
            departure.label = 'Attention: trajet sur demande, indiquez votre destination au conducteur en montant dans le bus.';
        }

        departures.push(departure);
        currentDepartureTime = addMinutes(currentDepartureTime, 2);
    }

    return departures;
}

const transferLines0: LineConnection[] = [];
const departureData0 = {
    direction: 'Monthey-Ville Aigle-Gare-Tommy-Test-bonjour-les-copains-comment-ca-va-vous-moi-ca-va-bien-lol-quoique-jesaispas',
    lineName: '144',
    lineBgColor: '#009b3b',
    lineTextColor: '#ffffff',
    stopName: 'Aigle Gare',
    track: 'C',
    transport: 'TRAINREGIO',
    coordinates: 'POINT (6.9479303532408579 46.2538361633005165)',
    thermo: [
        {
            stopName: "Val d'Illiez",
            hasBookingRequirements: false,
            transferLines: [
                {
                    backgroundColor: '#32A146',
                    textColor: '#FFFFFF',
                    direction: 'Les Crosets Nom Beaucoup Trop Longggggg',
                    transport: '',
                    name: '81',
                },
                { backgroundColor: '#32A146', textColor: '#FFFFFF', direction: 'Champoussin', transport: '', name: '82' },
            ],
        },
        {
            stopName: 'Champéry Gare / Téléphérique',
            hasBookingRequirements: false,
            transferLines: [
                { backgroundColor: '#422B91', textColor: '#FFFFFF', direction: 'Leysin', transport: '', name: 'R25' },
                { backgroundColor: '#F6C614', textColor: '#000000', direction: 'Torgon', transport: '', name: '142' },
                { backgroundColor: '#484B4D', textColor: '#FFFFFF', direction: 'Villeneuve', transport: '', name: 'IR90' },
            ],
        },
        {
            stopName: 'Champéry Gare / Téléphérique',
            hasBookingRequirements: false,
            transferLines: [
                { backgroundColor: '#422B91', textColor: '#FFFFFF', direction: 'Leysin', transport: '', name: 'R25' },
                { backgroundColor: '#F6C614', textColor: '#000000', direction: 'Torgon', transport: '', name: '142' },
                { backgroundColor: '#484B4D', textColor: '#FFFFFF', direction: 'Villeneuve', transport: '', name: 'IR90' },
            ],
        },
        {
            stopName: 'Champéry Gare / Téléphérique',
            hasBookingRequirements: false,
            transferLines: [
                { backgroundColor: '#422B91', textColor: '#FFFFFF', direction: 'Leysin', transport: '', name: 'R25' },
                { backgroundColor: '#F6C614', textColor: '#000000', direction: 'Torgon', transport: '', name: '142' },
                { backgroundColor: '#484B4D', textColor: '#FFFFFF', direction: 'Villeneuve', transport: '', name: 'IR90' },
            ],
        },
        {
            stopName: 'Champéry Gare / Téléphérique',
            hasBookingRequirements: false,
            transferLines: [
                { backgroundColor: '#422B91', textColor: '#FFFFFF', direction: 'Leysin', transport: '', name: 'R25' },
                { backgroundColor: '#F6C614', textColor: '#000000', direction: 'Torgon', transport: '', name: '142' },
                { backgroundColor: '#484B4D', textColor: '#FFFFFF', direction: 'Villeneuve', transport: '', name: 'IR90' },
            ],
        },
        // {
        //     stopName: 'Champéry Gare / Téléphérique',
        //     hasBookingRequirements: false,
        //     transferLines: [
        //         { backgroundColor: '#422B91', textColor: '#FFFFFF', direction: 'Leysin', transport: '', name: 'R25' },
        //         { backgroundColor: '#F6C614', textColor: '#000000', direction: 'Torgon', transport: '', name: '142' },
        //         { backgroundColor: '#484B4D', textColor: '#FFFFFF', direction: 'Villeneuve', transport: '', name: 'IR90' },
        //     ],
        // },
    ],
};

const departureData1 = {
    direction: 'Champéry',
    lineName: 'R26',
    lineBgColor: '#422B91',
    lineTextColor: '#ffffff',
    stopName: 'Champéry',
    track: 'B',
    coordinates: 'POINT (6.9478503532408579 46.2538361633005165)',
    transport: 'BUS',
    thermo: [
        {
            stopName: "Val d'Illiez",
            hasBookingRequirements: false,
            transferLines: [
                { backgroundColor: '#32A146', textColor: '#FFFFFF', direction: 'Les Crosets', transport: '', name: '81' },
                { backgroundColor: '#32A146', textColor: '#FFFFFF', direction: 'Champoussin', transport: '', name: '82' },
            ],
        },
        {
            stopName: 'En Charnet',
            hasBookingRequirements: true,
            transferLines: [],
        },
        {
            stopName: 'Aigle place du marché',
            hasBookingRequirements: false,
            transferLines: [],
        },
        {
            stopName: 'Champéry Gare / Téléphérique',
            hasBookingRequirements: false,
            transferLines: [
                { backgroundColor: '#422B91', textColor: '#FFFFFF', direction: 'Leysin', transport: '', name: 'R25' },
                { backgroundColor: '#F6C614', textColor: '#000000', direction: 'Torgon', transport: '', name: '142' },
                { backgroundColor: '#484B4D', textColor: '#FFFFFF', direction: 'Villeneuve', transport: '', name: 'IR90' },
            ],
        },
    ],
};

const delays = ['00:10:00', '00:02:00', '00:10:00', '01:00:00', '-00:05:00', ...new Array<string>(100).fill('00:00:00')];
