import { Observable } from 'rxjs';
import { Message } from '../models/message.model';
import { DeparturesResult } from '../models/departures-result.model';
import { InjectionToken } from '@angular/core';
import { GetDepartureResponse } from '../models/data.model';

export interface DataService {
    $fetchDepartures(serviceUrl: string): Observable<DeparturesResult>;

    $fetchDisruptionMessages(messageUrl: string): Observable<Message[]>;

    $getSyntheseDepartures(serviceUrl: string): Observable<GetDepartureResponse>;
}

export const DATA_SERVICE_TOKEN = new InjectionToken<DataService>('DATA_SERVICE_TOKEN');
