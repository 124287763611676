import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DisplayConfiguration } from '../models/display-configuration.model';
import * as config from './display-config.validator';
import { ConfigService } from './config.service';
import { LoggingService } from '@traas/common/logging';
import { TechnicalError } from '@traas/common/models';
import { EivErrorCodes } from '../models/eiv-error-codes';

export const RoutesNames = {
    MapDepartures: 'map-departures',
    DeparturesList: 'departures-list',
    ConfigurationError: 'configuration-error',
    LoadingPage: 'loading-page',
    DebugPickDisplay: 'debug-pick-display',
    HealthCheck: 'health',
};

@Injectable({
    providedIn: 'root',
})
export class RoutingService {
    readonly #logger = inject(LoggingService);
    #hasAlreadyBeenRedirected = false;

    constructor(
        private readonly router: Router,
        private readonly configService: ConfigService,
    ) {}

    async redirectUsingConfig(displayConfiguration: DisplayConfiguration): Promise<boolean> {
        const navigationExtras = this.configService.createNavigationExtras();
        const validateDisplayConfig = config.validateDisplayConfig(displayConfiguration);
        if (!validateDisplayConfig.isValid) {
            return this.configService.handleConfigError(navigationExtras, validateDisplayConfig.errorCode, displayConfiguration);
        }

        // si on a déjà redirigé une fois vers le bon écran, ça veut dire que la configuration vient de changer et donc
        // qu'il faut refresh intégralement l'app (d'où le location.replace) pour éviter les bugs graphiques
        if (this.#hasAlreadyBeenRedirected) {
            location.replace(location.origin);
        }

        if (displayConfiguration.mapNumberOfRows > 0) {
            this.#hasAlreadyBeenRedirected = true;
            return this.router.navigate([RoutesNames.MapDepartures], navigationExtras);
        }

        if (displayConfiguration.mapNumberOfRows === 0) {
            this.#hasAlreadyBeenRedirected = true;
            return this.router.navigate([RoutesNames.DeparturesList], navigationExtras);
        }

        this.#logger.logError(
            new TechnicalError(
                'configuration error, routing to display screen failed',
                EivErrorCodes.Initialization.BadDisplayConfiguration,
                undefined,
                {
                    displayConfiguration,
                },
            ),
        );
        return this.router.navigate([RoutesNames.ConfigurationError], navigationExtras);
    }

    async redirectToDebugPage(): Promise<boolean> {
        return this.router.navigate([RoutesNames.DebugPickDisplay]);
    }

    async redirectToConfigurationErrorPage(): Promise<boolean> {
        return this.router.navigate([RoutesNames.ConfigurationError]);
    }
}
