import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ConfigService, RedirectService, VitalsService } from '@traas/eiv/common';

@Component({
    selector: 'eiv-health-check-page',
    templateUrl: './health-check-page.component.html',
    styleUrls: ['./health-check-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthCheckPageComponent implements OnInit, OnDestroy {
    readonly #vitals = inject(VitalsService);
    readonly #configService = inject(ConfigService);
    readonly #redirectService = inject(RedirectService);

    readonly manifestJson = this.#vitals.getManifestJson();
    readonly $displayConfig = this.#configService.$getDisplayConfiguration();

    readonly displayConfigEndpointStatus = this.#vitals.getDisplayConfigEndpointStatus();
    readonly syntheseDepartureEndpointStatus = this.#vitals.getSyntheseDepartureEndpointStatus();
    readonly syntheseDisruptionMessageEndpointStatus = this.#vitals.getSyntheseDisruptionMessageEndpointStatus();

    ngOnInit(): void {
        this.#redirectService.canRedirect = false;
    }

    ngOnDestroy(): void {
        this.#redirectService.canRedirect = true;
    }
}
