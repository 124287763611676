import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import {
    ConfigService,
    ENVIRONMENT_SERVICE_TOKEN,
    EnvironmentService,
    MonitoringService,
    RedirectService,
    RoutingService,
} from '@traas/eiv/common';
import { Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

interface Manifest {
    version: string;
    commit: string;
}

@Component({
    selector: 'traas-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
    #monitoringService = inject(MonitoringService);
    readonly #$onDestroy = new Subject<void>();
    readonly #displayConfigPollingSubscription: Subscription;
    manifest?: Manifest;

    constructor(
        @Inject(ENVIRONMENT_SERVICE_TOKEN)
        private readonly environmentService: EnvironmentService,
        private readonly configService: ConfigService,
        private readonly routingService: RoutingService,
        private readonly redirectService: RedirectService,
        private http: HttpClient,
    ) {
        if (this.environmentService.getUseFakeData()) {
            console.debug('App is in mode fake data.');
            console.debug('window.location.href', window.location.href);

            if (
                window.location.href === 'http://localhost:4200' ||
                window.location.href === 'http://localhost:4200/' ||
                window.location.href === 'http://localhost:4200/loading-page'
            ) {
                console.debug('Clear mocked config.');
                // Will force to be redirected on debug page
                this.configService.clearMockedConfigProperties();
            }
            if (this.configService.hasMockedConfigPropertiesEmpty()) {
                this.routingService.redirectToDebugPage();
            }
        }

        this.#displayConfigPollingSubscription = this.configService.subscribeToDisplayConfigurationPolling();

        this.#loadManifest();
    }

    ngOnInit(): void {
        this.redirectService.init();
        this.#monitoringService.init();
    }

    ngOnDestroy(): void {
        this.#$onDestroy.next();
        this.#$onDestroy.complete();
        this.#displayConfigPollingSubscription.unsubscribe();
    }

    #loadManifest(): void {
        this.http.get<any>('manifest.json').subscribe({
            next: (manifest: Manifest) => {
                this.manifest = manifest;
            },
            error: (error: any) => {
                console.error('Erreur lors du chargement du manifest:', error);
            },
        });
    }
}
