// not okay at all but let's pretends it is. Sometimes the server provides a number like |SL3
// sometimes the official name 85_000011_SL3. Here we try to cover both case. This should definitely
// be solved passing all the requests through (and providing the icons from) the middleware
const lineFilePath = 'assets/imgs/lines.min.svg';
function getSymbolUrl(symbolName: string): string {
    return `${lineFilePath}#${symbolName}`;
}

export const LEGACY_LINE_ICONS_DICTIONARY = {
    '85_000011_SL1': getSymbolUrl('85_000011_SL1'),
    '85_000011_SL2': getSymbolUrl('85_000011_SL2'),
    '85_000011_SL3': getSymbolUrl('85_000011_SL3'),
    '85_000011_SL4': getSymbolUrl('85_000011_SL4'),
    '85_000011_SL5': getSymbolUrl('85_000011_SL5'),
    '85_000011_SL6': getSymbolUrl('85_000011_SL6'),
    '85_000011_TER': getSymbolUrl('85_000011_TER'),
    '85_776_TACTANG': getSymbolUrl('85_776_TACTANGO'),
    '85_818_TPCBUS': getSymbolUrl('85_818_TPCBUS'),
    '85_818_TPCNOC': getSymbolUrl('85_818_TPCNOC'),
    '85_818_TPCSKI': getSymbolUrl('85_818_TPCSKI'),
    '85_818_TPCTAD': getSymbolUrl('85_818_TPCTAD'),
    '85_87_ARA_LT71': getSymbolUrl('85_87_ARA_LT71'),
    '85_87_ARA_LT72': getSymbolUrl('85_87_ARA_LT72'),
    '85_87_ARA_LT73': getSymbolUrl('85_87_ARA_LT73'),
    '85_87_ARA_LT74': getSymbolUrl('85_87_ARA_LT74'),
    '87_87_LEX_TER': getSymbolUrl('85_000011_TER'),
    BLT71: getSymbolUrl('85_87_ARA_LT71'),
    BLT72: getSymbolUrl('85_87_ARA_LT72'),
    BLT73: getSymbolUrl('85_87_ARA_LT73'),
    BLT74: getSymbolUrl('85_87_ARA_LT74'),
    IC57: getSymbolUrl('IC57'),
    IR57: getSymbolUrl('IR57'),
    IR90: getSymbolUrl('IR90'),
    RE: getSymbolUrl('RE'),
    SL1: getSymbolUrl('85_000011_SL1'),
    SL2: getSymbolUrl('85_000011_SL2'),
    SL3: getSymbolUrl('85_000011_SL3'),
    SL4: getSymbolUrl('85_000011_SL4'),
    SL5: getSymbolUrl('85_000011_SL5'),
    SL6: getSymbolUrl('85_000011_SL6'),
    T2: getSymbolUrl('85_776_TACTANGO'),
    TANGO: getSymbolUrl('85_776_TACTANGO'),
    TER: getSymbolUrl('85_000011_TER'),
    '85_000199_M1': getSymbolUrl('85_000199_M1'),
    '85_000199_M2': getSymbolUrl('85_000199_M2'),
    '85_000199_M3': getSymbolUrl('85_000199_M3'),
    '85_000199_M4': getSymbolUrl('85_000199_M4'),
    RE33: getSymbolUrl('RE33'),
};

export const LINE_ICONS_BASE_PATH = 'assets/imgs/lines';

export const LINE_ICONS_DICTIONARY = {
    IC1: `${LINE_ICONS_BASE_PATH}/IC01.svg`,
    EC: `${LINE_ICONS_BASE_PATH}/EC.svg`,
    IC5: `${LINE_ICONS_BASE_PATH}/IC05.svg`,
    IR15: `${LINE_ICONS_BASE_PATH}/IR15.svg`,
    RL1: `${LINE_ICONS_BASE_PATH}/RL1.svg`,
    RL2: `${LINE_ICONS_BASE_PATH}/RL2.svg`,
    RL3: `${LINE_ICONS_BASE_PATH}/RL3.svg`,
    RL4: `${LINE_ICONS_BASE_PATH}/RL4.svg`,
    RL5: `${LINE_ICONS_BASE_PATH}/RL5.svg`,
    RL6: `${LINE_ICONS_BASE_PATH}/RL6.svg`,
    RE33: `${LINE_ICONS_BASE_PATH}/RE33.svg`,
};

export const LINE_ICONS_RECTANGLE_DICTIONARY = {
    BT71: `${LINE_ICONS_BASE_PATH}/BT71.svg`,
    BT72: `${LINE_ICONS_BASE_PATH}/BT72.svg`,
    BT74: `${LINE_ICONS_BASE_PATH}/BT74.svg`,
    '271': `${LINE_ICONS_BASE_PATH}/BT71.svg`,
    '272': `${LINE_ICONS_BASE_PATH}/BT72.svg`,
    '274': `${LINE_ICONS_BASE_PATH}/BT74.svg`,
};

export function getLineCodeFormattedToSVGSymbol(lineCode: string): string {
    return lineCode ? lineCode.replace('|', '') : '';
}
