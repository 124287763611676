import { DisplayConfiguration } from '../models/display-configuration.model';
import { HEADER_ROW, SIZE_OF_EXPANDED_DEPARTURE } from '../constants/screen-layout-configuration.contants';

// eslint-disable-next-line no-shadow
export enum ConfigErrorCode {
    InvalidRefreshDelayInSec = 1, // refreshDelayInSec non définie ou inférieur à 1.
    UndefinedTotalNumberOfRows = 2, // totalNumberOfRows non définie.
    MissingDisplayTrack = 3, // displayTrack non définie.
    MissingDisplayClock = 4, // displayClock non définie.
    MapNumberOfRowsGreaterThanTotal = 5, // mapNumberOfRows supérieure ou égale à totalNumberOfRows.
    MissingTerminalCoordinates = 6, // terminalCoordinates non définie alors que carte affichée.
    MissingTerminalLatitudeOrLongitude = 7, // terminalCoordinates latitude et/ou longitude non définies alors que carte affichée.
    MissingMapCenterCoordinates = 8, // mapCenterCoordinates non définie  alors que carte affichée.
    MissingMapCenterLatitudeOrLongitudeOrZoom = 9, // mapCenterCoordinates latitude/longitude/zoomLevel non définies alors que carte affichée.
    InvalidNumberOfRowsAcrossMapAndOpenAndTotal = 10, // totalNumberOfRows est inférieure à mapNumberOfRows + numberOfOpenRows * SIZE_OF_EXPANDED_DEPARTURE + HEADER_ROW
    NoDisplayConfiguration = 11, // pas de configuration d'affichage récupérée.
}

export interface DisplayConfigValidationResult {
    isValid: boolean;
    errorCode?: ConfigErrorCode;
}

export function validateDisplayConfig(configuration: DisplayConfiguration): DisplayConfigValidationResult {
    const hasMapInScreen = configuration.mapNumberOfRows;
    const totalNumberRowsForExpandedDepartures = configuration.numberOfOpenRows * SIZE_OF_EXPANDED_DEPARTURE;

    if (!configuration.refreshDelayInSec || configuration.refreshDelayInSec < 1) {
        console.warn('invalid refreshDelayInSec');
        return { isValid: false, errorCode: ConfigErrorCode.InvalidRefreshDelayInSec };
    }

    if (!configuration.totalNumberOfRows) {
        console.warn('invalid totalNumberOfRows on configuration', configuration);
        return { isValid: false, errorCode: ConfigErrorCode.UndefinedTotalNumberOfRows };
    }

    if (configuration.displayTrack == null) {
        console.warn('missing displayTrack', configuration);
        return { isValid: false, errorCode: ConfigErrorCode.MissingDisplayTrack };
    }

    if (configuration.displayClock == null) {
        console.warn('missing displayClock', configuration);
        return { isValid: false, errorCode: ConfigErrorCode.MissingDisplayClock };
    }

    if (configuration.mapNumberOfRows >= configuration.totalNumberOfRows) {
        console.warn("mapNumberOfRows can't be >= totalNumberOfRows on configuration", configuration);
        return { isValid: false, errorCode: ConfigErrorCode.MapNumberOfRowsGreaterThanTotal };
    }

    if (hasMapInScreen && !configuration.terminalCoordinates) {
        console.warn('missing terminalCoordinates on configuration', configuration);
        return { isValid: false, errorCode: ConfigErrorCode.MissingTerminalCoordinates };
    }

    if (hasMapInScreen && (!configuration.terminalCoordinates.latitude || !configuration.terminalCoordinates.longitude)) {
        console.warn('missing terminalCoordinates latitude/longitude on configuration', configuration);
        return { isValid: false, errorCode: ConfigErrorCode.MissingTerminalLatitudeOrLongitude };
    }

    if (hasMapInScreen && !configuration.mapCenterCoordinates) {
        console.warn('missing mapCenterCoordinates on configuration', configuration);
        return { isValid: false, errorCode: ConfigErrorCode.MissingMapCenterCoordinates };
    }

    if (
        hasMapInScreen &&
        (!configuration.mapCenterCoordinates.latitude || !configuration.mapCenterCoordinates.longitude || !configuration.mapZoomLevel)
    ) {
        console.warn('missing mapCenterCoordinates latitude/longitude/zoomLevel on configuration', configuration);
        return { isValid: false, errorCode: ConfigErrorCode.MissingMapCenterLatitudeOrLongitudeOrZoom };
    }

    if (configuration.totalNumberOfRows < configuration.mapNumberOfRows + totalNumberRowsForExpandedDepartures + HEADER_ROW) {
        console.warn('invalid number of rows across map/open/total on configuration', configuration);
        return { isValid: false, errorCode: ConfigErrorCode.InvalidNumberOfRowsAcrossMapAndOpenAndTotal };
    }

    return { isValid: true };
}
