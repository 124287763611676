import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { Departure, GetDepartureResponse } from '../models/data.model';
import { HttpClient } from '@angular/common/http';
import { Message } from '../models/message.model';
import { DataService } from './data.service';
import { addMinutes, isAfter } from 'date-fns';
import { createDeparturesStub } from './fake-departures-factory.utils';
import { ConfigService } from './config.service';
import { DeparturesResult } from '../models/departures-result.model';

const MESSAGES_MOCK_DATA_FILEPATH = 'assets/mock/messages-data.json';

@Injectable()
export class MockDataService implements DataService {
    #refreshId = 0;
    #fakeDepartures: Departure[] | undefined;
    #dateResetFakeData: Date | undefined;

    constructor(
        private readonly configService: ConfigService,
        private readonly http: HttpClient,
    ) {}

    $getSyntheseDepartures(serviceUrl: string): Observable<GetDepartureResponse> {
        return of({
            departures: [],
        });
    }

    $fetchDepartures(serviceUrl: string): Observable<DeparturesResult> {
        const minutesBetweenNewData = 5;

        console.log('fetching fake departures');
        if (!this.#dateResetFakeData || !this.#fakeDepartures || isAfter(new Date(), this.#dateResetFakeData)) {
            if (!this.configService.fakeDeparturesConfig) {
                throw new Error('missing fakeDepartureDataConfig in config');
            }
            console.log('recreating fake departures');
            this.#fakeDepartures = createDeparturesStub(this.configService.fakeDeparturesConfig);
            this.#dateResetFakeData = addMinutes(new Date(), minutesBetweenNewData);
        }

        // we create a new collection (by cloning the existing one) as would a http call do (avoiding JS ids that don't change)
        const fakeDepartures: Departure[] = JSON.parse(JSON.stringify(this.#fakeDepartures));
        // now we modify the second one of the list every call, to have a set of data that slightly changes
        if (fakeDepartures.length >= 2) {
            if (this.#refreshId % 3 === 0) {
                fakeDepartures[1].departureDelay = '00:03:00';
                fakeDepartures[1].track = 'D';
                fakeDepartures[1].isCanceled = false;
            } else if (this.#refreshId % 3 === 1) {
                fakeDepartures[1].departureDelay = '-00:03:00';
                fakeDepartures[1].track = 'A';
                fakeDepartures[1].isCanceled = false;
            } else if (this.#refreshId % 3 === 2) {
                fakeDepartures[1].departureDelay = '00:00:00';
                fakeDepartures[1].isCanceled = true;
            }
        }
        this.#refreshId += 1;

        return of({ departures: fakeDepartures });
    }

    $fetchDisruptionMessages(serviceUrl: string): Observable<Message[]> {
        return this.http.get<Message[]>(MESSAGES_MOCK_DATA_FILEPATH).pipe(
            delay(500),
            tap((data) => console.log('fake messages data', data)),
            // map(() => null), // -> retour invalide de synthese
            // map(() => []), // -> aucun messages
            map((messages) => {
                if (messages === null) {
                    throw new Error('Invalid data');
                }
                return messages;
            }),
        );
    }
}
