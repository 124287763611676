import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetDepartureResponse } from '../models/data.model';
import { Message } from '../models/message.model';
import { DataService } from './data.service';
import { DeparturesResult } from '../models/departures-result.model';
import { DepartureCacheService } from './departure-cache.service';
import { LoggingService } from '@traas/common/logging';
import { TechnicalError } from '@traas/common/models';
import { EivErrorCodes } from '../models/eiv-error-codes';

/**
 * @class SyntheseDataService
 *
 * @implements DataService
 *
 * @description
 * A class for fetching display configurations and data likes departures, messages, etc.
 *
 * It's not providedIn: 'root' because there is a custom provider to inject this one or MockDataService.
 *
 */
@Injectable()
export class SyntheseDataService implements DataService {
    readonly #departuresCache = inject(DepartureCacheService);
    readonly #logger = inject(LoggingService);
    #disruptionMessagesCache: Message[] | undefined;

    constructor(private readonly http: HttpClient) {}

    $fetchDepartures(serviceUrl: string): Observable<DeparturesResult> {
        return this.$getSyntheseDepartures(serviceUrl).pipe(
            map((syntheseResponse) => {
                if (!syntheseResponse || !syntheseResponse.departures) {
                    // in case of this kind of error, we don't use the cache (this is intended)
                    const errorMsg = 'Invalid departures';
                    this.#logger.logError(
                        new TechnicalError(errorMsg, EivErrorCodes.DepartureList.FetchDepartureSyntheseEmptyResponse, undefined, {
                            syntheseResponse,
                        }),
                    );
                    return { error: new Error(errorMsg) };
                }

                const departures = syntheseResponse.departures;
                this.#departuresCache.data = departures;
                return { departures };
            }),
            catchError((error) => {
                this.#logger.logError(
                    new TechnicalError("couldn't fetch departures", EivErrorCodes.DepartureList.FetchDepartureNetworkError, error),
                );
                const cachedDepartures = this.#departuresCache.data;
                return cachedDepartures?.length ? of({ departures: cachedDepartures }) : of({ error });
            }),
        );
    }

    $fetchDisruptionMessages(messageUrl: string): Observable<Message[]> {
        const defaultValue: Message[] = [];
        return this.http.get<Message[]>(messageUrl).pipe(
            map((syntheseResponse) => {
                if (!syntheseResponse) {
                    // in case of this kind of error, we don't use the cache (this is intended)
                    this.#logger.logError(
                        new TechnicalError(
                            'Invalid disruption messages',
                            EivErrorCodes.DisruptionMessages.SyntheseEmptyResponse,
                            undefined,
                            { syntheseResponse },
                        ),
                    );
                    return defaultValue;
                }
                this.#disruptionMessagesCache = syntheseResponse;
                return syntheseResponse;
            }),
            catchError((error) => {
                this.#logger.logError(
                    new TechnicalError("couldn't fetch disruption messages", EivErrorCodes.DisruptionMessages.NetworkError, error),
                );
                return of(this.#disruptionMessagesCache ?? defaultValue);
            }),
        );
    }

    $getSyntheseDepartures(serviceUrl: string): Observable<GetDepartureResponse> {
        return this.http.get<GetDepartureResponse>(serviceUrl);
    }
}
