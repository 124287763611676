import { inject, Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { VitalsService } from './vitals.service';
import { LoggingService } from '@traas/common/logging';
import { TechnicalError } from '@traas/common/models';
import { EivErrorCodes } from '../models/eiv-error-codes';

const KEYWORD = 'TPCVITALS';
const BEGIN_TAG = KEYWORD + '_BEGIN';
const END_TAG = KEYWORD + '_END';

@Injectable({
    providedIn: 'root',
})
export class MonitoringService {
    readonly #vitals = inject(VitalsService);
    readonly #logger = inject(LoggingService);

    init(): void {
        // 10s after startup, then every 10min.
        timer(10_000, 600_000).subscribe(() => this.logVitals());
    }

    async logVitals(): Promise<void> {
        try {
            const [
                manifestResult,
                displayConfigResult,
                displayConfigEndpointResult,
                departureEndpointResult,
                disruptionMessageEndpointResult,
            ] = await Promise.allSettled([
                this.#vitals.getManifestJson(),
                this.#vitals.getDisplayConfig(),
                this.#vitals.getDisplayConfigEndpointStatus(),
                this.#vitals.getSyntheseDepartureEndpointStatus(),
                this.#vitals.getSyntheseDisruptionMessageEndpointStatus(),
            ]);

            const promiseValue = <T>(result: PromiseSettledResult<T>): T | null => (result.status === 'fulfilled' ? result.value : null);

            const vitals = {
                manifest: promiseValue(manifestResult),
                displayConfig: promiseValue(displayConfigResult),
                displayConfigEndpoint: promiseValue(displayConfigEndpointResult),
                departureEndpoint: promiseValue(departureEndpointResult),
                disruptionMessageEndpoint: promiseValue(disruptionMessageEndpointResult),
            };

            this.#writeVitals(vitals);
        } catch (err) {
            this.#logger.logError(new TechnicalError('Error logging vitals', EivErrorCodes.Vitals.LogVitals, err));
            this.#writeVitals(null);
        }
    }

    #writeVitals(vitals: unknown): void {
        console.info(BEGIN_TAG + JSON.stringify(vitals) + END_TAG);
    }
}
