import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { initSentry } from '@traas/eiv/common';
import * as Sentry from '@sentry/angular-ivy';

initSentry(
    environment.production,
    'https://4a6b7c9d6a57848eb10cdbee3e993321@o311737.ingest.sentry.io/4505911519215616',
    environment.sentryRelease,
    undefined, // the environment will come from the downloaded config.json in the app initializer
    environment.currentCommitHash,
);

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
