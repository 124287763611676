export const ErrorCodes = {
    Departure: {
        UpdateLineFilters: 'departure.updateLineFilters',
        Fetch: 'departure.fetch',
        DrawOnMap: 'departure.drawOnMap',
    },
    Itinerary: {
        LoadMore: 'itinerary.loadMore',
        LinkSearch: 'itinerary.linkSearch',
        AdapterError: 'itinerary.adapterError',
        Fetch: 'itinerary.fetch',
        FetchSupersaverArticles: 'itinerary.fetchSupersaverArticles',
    },
    Preferences: {
        UpdateNationalMarketingConsent: 'preferences.updateNationalMarketingConsent',
    },
    FrequentTravelers: {
        Fetch: 'frequentTravelers.fetch',
    },
    Graphql: {
        Mapping: 'graphql.mapping',
        Request: 'graphql.request',
    },
    Booking: {
        Fetch: 'booking.fetch',
        FetchByCard: 'booking.fetchByCard',
    },
    GenderTitles: {
        Init: 'gender-titles.init',
        FetchTitles: 'gender-titles.fetchTitles',
    },
    Purchase: {
        BuyArticles: 'purchase.buyArticles',
        PrepareItineraryArticle: 'purchase.prepareItineraryArticle',
        GenerateTicket: 'purchase.generateTicket',
        Generating: 'purchase.generating',
        CancellationSucceed: 'purchase.cancellationSucceed',
        CancellationFailed: 'purchase.cancellationFailed',
        CancellationInApp: 'purchase.cancellationInApp',
        WaitingSaferpayPayment: 'purchase.waitingSaferpayPayment',
        SetTicketsToSeen: 'purchase.setTicketsToSeen',
        PrepareArticlesBundle: 'purchase.prepareArticlesBundle',
        SetCheckoutStep: 'purchase.setCheckoutStep',
        LoadMarketingConsent: 'purchase.loadMarketingConsent',
        SaferpayPaymentAborted: 'purchase.saferpayPaymentAborted',
        SaferpayPaymentFailed: 'purchase.saferpayPaymentFailed',
        CartTypeNotSupported: 'purchase.cartTypeNotSupported',
        GetOrderStatus: 'purchase.getOrderStatus',
        Pay: 'purchase.pay',
        Unknown: 'purchase.unknown',
        FinalizeOrder: 'purchase.finalizeOrder',
        CancelOrder: 'purchase.cancelOrder',
        CustomerNotValid: 'purchase.customerNotValid',
        GoBack: 'purchase.goBack',
        StopRequest: 'purchase.stopRequest',
        SaveItinerary: 'purchase.saveItinerary',
    },
    Environment: {
        MissingValue: 'environment.missingValue',
    },
    Map: {
        NoMap: 'map.noMap',
        MissingLayer: 'map.missingLayer',
        Marker: 'map.marker',
        DynamicLayer: 'map.dynamicLayer',
        FitBounds: 'map.fitBounds',
        GetBounds: 'map.getBounds',
        FollowGps: 'map.followGps',
        MoveOnGpsPosition: 'map.moveOnGpsPosition',
    },
    Bookmark: {
        Migration: 'bookmark.migration',
        MigrationFromServer: 'bookmark.migrationFromServer',
        UseGps: 'bookmark.useGps',
        TileClick: 'bookmark.tileClick',
        InvalidBookmark: 'bookmark.invalidBookmark',
    },
    QuickTicket: {
        UpdateStorage: 'quickTicket.updateStorage',
        Fetch: 'quickTicket.fetch',
    },
    InfoTraffic: {
        Fetch: 'infoTraffic.fetch',
    },
    Authentication: {
        AuthenticateOnGateway: 'authentication.authenticateOnGateway',
        BasicAuth: 'authentication.basicAuth',
        GetCustomer: 'authentication.getCustomer',
    },
    Sms: 'sms',
    Swisspass: {
        TooManyActivations: 'swisspass.tooManyActivations',
        Activate: 'swisspass.activate',
        AccountBlocked: 'swisspass.accountBlocked',
        SsoRedirect: 'swisspass.ssoRedirect',
        NativeMethod: 'swisspass.nativeMethod',
    },
    Account: {
        HasHalfFare: 'account.hasHalfFare',
        NoProfileFoundByCard: 'account.noProfileFoundByCard',
    },
    Line: {
        CreateLineStyle: 'line.createLineStyle',
        Fetch: 'line.fetch',
    },
    SearchPlace: {
        PickPlace: 'searchPlace.pickPlace',
        CreateGpsPlace: 'searchPlace.createGpsPlace',
        CreateResponse: 'searchPlace.createResponse',
    },
    StartupNotification: {
        Fetch: 'startupNotification.fetch',
    },
    Technical: {
        Analytics: 'technical.analytics',
        ErrorParsing: 'technical.errorParsing',
        PipeTransform: 'technical.pipeTransform',
        ScrollToFirstFutureJourney: 'technical.scrollToFirstFutureJourney',
        UpdateDataVersion: 'technical.updateDataVersion',
        JsonParse: 'technical.jsonParse',
    },
    NearestStop: {
        Fetch: 'nearestStop.fetch',
    },
    PhysicalStops: {
        FetchByLatLng: 'physicalStops.fetchByLatLng',
        FetchByBBox: 'physicalStops.fetchByBBox',
    },
    Ticket: {
        MissingData: 'ticket.missingData',
    },
};

/**
 * Ces codes d'erreurs sont aussi utilisés par la gateway pour identifier les erreurs.
 * Ils peuvent être remplis partiellement ici, en fonction des besoins.
 *
 * Par contre si une valeur de code change ici, il faut aussi la changer dans la gateway. Et vice versa.
 *
 * Le fichier GW se trouve ici : libs/errors/src/lib/gateway-error-code.ts
 */
export const GatewayErrorCodes = {
    Purchase: {
        OutOfNetwork: 'purchase.outOfNetwork',
    },
};
