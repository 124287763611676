<div class="ion-padding">
  @if ($screenDefinitionIsWrong | async) {
    <div class="size-warning">
      <h3>Attention, la taille de ton écran n'est pas 1920x1080, tu n'es pas dans les conditions des bornes</h3>
    </div>
  }

  <ion-button expand="block" (click)="redirectToMockedDeparturesList()"> <ion-icon name="list"></ion-icon>Departures List </ion-button>
  <br />

  <ion-button expand="block" (click)="redirectToDeparturesListWithConfig(30, 3, 0)" style="--background: gray">
    <ion-icon name="timer-outline"></ion-icon>Outdated departures List
  </ion-button>
  <br />

  <ion-button expand="block" (click)="redirectToMockedDeparturesListWithExpandedDepartures()">
    <ion-icon name="expand-outline"></ion-icon>Departures List with expanded departures
  </ion-button>
  <br />

  <ion-button expand="block" (click)="redirectToDeparturesListWithConfig(30, 0, 15)" style="--background: red">
    <ion-icon name="alert-circle-outline"></ion-icon>Canceled departures List
  </ion-button>
  <br />

  <ion-button expand="block" (click)="redirectToMockedMapDepartures()"> <ion-icon name="map"></ion-icon>Map Departures </ion-button>
  <br />

  <ion-button expand="block" (click)="redirectToMockedPlatformDeparture()" style="--background: blue">
    <ion-icon name="search"></ion-icon>Platform Departure
  </ion-button>
  <br />

  <ion-button expand="block" (click)="redirectToDeparturesListWithConfig(30, 100, 0)" style="--background: gray">
    <ion-icon name="alert"></ion-icon>All outdated
  </ion-button>
  <br />

  <ion-button expand="block" (click)="redirectToMockedConfigurationError()" style="--background: red">
    <ion-icon name="alert"></ion-icon>Configuration Error
  </ion-button>
</div>
