import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeparturesListComponent } from './components/departures-list/departures-list.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { DepartureItemComponent } from './components/departure-item/simple-departure-item/departure-item.component';
import { CenteredSpinnerComponent } from './components/centered-spinner/centered-spinner.component';
import { IonicModule } from '@ionic/angular';
import { CustomLineIconComponent } from './components/custom-line-icon/custom-line-icon.component';
import { LineIconsComponent } from './components/line-icons/line-icons.component';
import { ConnectionsComponent } from './components/connections/connections.component';
import { VehicleIconComponent } from './components/vehicle-icon/vehicle-icon.component';
import { SymbolSvgComponent } from './components/symbol-svg/symbol-svg.component';
import { CustomSymbolSvgComponent } from './components/custom-symbol-svg/custom-symbol-svg.component';
import { TrackIconComponent } from './components/track-icon/track-icon.component';
import { CustomDepartureTimeComponent } from './components/custom-departure-time/custom-departure-time.component';
import { DatetimeComponent } from './components/datetime/datetime.component';
import { ExpandedDepartureItemComponent } from './components/departure-item/expanded-departure-item/expanded-departure-item.component';
import { ThermometerComponent } from './components/thermometer/thermometer.component';
import { PathComponent } from './components/departure-item/expanded-departure-item/path/path.component';
import { DepartureHeaderComponent } from './components/departure-header/departure-header.component';
import { DisruptionMessagesOverlayComponent } from './containers/disruption-messages-overlay/disruption-messages-overlay.component';
import { BannerComponent } from './components/banner/banner.component';
import { FitTextDirective } from './directives/fit-text.directive';
import { NoDepartureComponent } from './components/no-departure/no-departure.component';

@NgModule({
    declarations: [
        BannerComponent,
        CenteredSpinnerComponent,
        ConnectionsComponent,
        CustomDepartureTimeComponent,
        CustomLineIconComponent,
        CustomSymbolSvgComponent,
        DatetimeComponent,
        DepartureHeaderComponent,
        DepartureItemComponent,
        DeparturesListComponent,
        DisruptionMessagesOverlayComponent,
        ExpandedDepartureItemComponent,
        FitTextDirective,
        LineIconsComponent,
        NoDepartureComponent,
        PathComponent,
        SymbolSvgComponent,
        ThermometerComponent,
        TrackIconComponent,
        VehicleIconComponent,
    ],
    exports: [
        BannerComponent,
        CenteredSpinnerComponent,
        CommonModule,
        ConnectionsComponent,
        CustomDepartureTimeComponent,
        CustomLineIconComponent,
        CustomSymbolSvgComponent,
        DatetimeComponent,
        DepartureHeaderComponent,
        DepartureItemComponent,
        DeparturesListComponent,
        DisruptionMessagesOverlayComponent,
        ExpandedDepartureItemComponent,
        IonicModule,
        LineIconsComponent,
        MatGridListModule,
        PathComponent,
        SymbolSvgComponent,
        ThermometerComponent,
        TrackIconComponent,
        VehicleIconComponent,
    ],
    imports: [CommonModule, IonicModule, MatGridListModule],
})
export class EivCommonModule {}
