import { Inject, Injectable, Signal } from '@angular/core';
import { DATA_SERVICE_TOKEN, DataService } from './data.service';
import { Departure } from '../models/data.model';
import { map, Observable, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { DeparturesResult } from '../models/departures-result.model';
import { ONE_SECOND_IN_MS } from '@traas/common/utils';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root',
})
export class DepartureService {
    constructor(
        @Inject(DATA_SERVICE_TOKEN)
        private readonly dataService: DataService,
        private readonly configService: ConfigService,
    ) {}

    $pollDepartures(refreshDelayInSec: number): Observable<DeparturesResult> {
        return timer(0, refreshDelayInSec * ONE_SECOND_IN_MS).pipe(
            switchMap(() => this.dataService.$fetchDepartures(this.configService.serviceUrl)),
            map((response) => {
                const displayConfiguration = this.configService.getDisplayConfiguration();
                if (displayConfiguration) {
                    return {
                        ...response,
                        departures: response.departures?.slice(0, displayConfiguration.nbDisplayedDepartures),
                    };
                } else {
                    return response;
                }
            }),
        );
    }

    getDepartureAlertMessage(departure: Departure): string | undefined {
        return departure.stop.messages[0]?.content;
    }

    departures(): Signal<DeparturesResult | undefined> {
        return toSignal(
            this.configService.$getValidDisplayConfiguration().pipe(switchMap((config) => this.$pollDepartures(config.refreshDelayInSec))),
        );
    }
}
