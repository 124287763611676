import { ErrorHandler, Injectable } from '@angular/core';
import { convertToError, LoggingService } from '@traas/common/logging';
import { FatalError } from '@traas/common/models';

/*
Take care to understand the difference between:
- ErrorHandler from angular/core
- ErrorHandler built by openit.
*/
@Injectable()
export class EivGlobalErrorHandler extends ErrorHandler {
    constructor(private logger: LoggingService) {
        super();
    }

    override async handleError(error: Error): Promise<void> {
        const innerError = convertToError(error) ?? new Error('unknown');
        const fatalError = error instanceof FatalError ? error : new FatalError(innerError);
        this.logger.logError(fatalError);
    }
}
