import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoadingPageComponent } from './pages/loading-page/loading-page.component';
import { ConfigurationErrorPageComponent } from './pages/configuration-error-page/configuration-error-page.component';
import { RoutesNames } from '@traas/eiv/common';
import { DebugPickDisplayPageComponent } from './pages/debug-pick-display-page/debug-pick-display-page.component';
import { HealthCheckPageComponent } from './pages/health-check-page/health-check-page.component';

const routes: Routes = [
    { path: '', redirectTo: RoutesNames.LoadingPage, pathMatch: 'full' },
    {
        path: RoutesNames.LoadingPage,
        component: LoadingPageComponent,
    },
    {
        path: RoutesNames.ConfigurationError,
        component: ConfigurationErrorPageComponent,
    },
    {
        path: RoutesNames.MapDepartures,
        loadChildren: () => import('@traas/eiv/feature-map-departures').then((m) => m.FeatureMapDeparturesModule),
    },
    {
        path: RoutesNames.DeparturesList,
        loadChildren: () => import('@traas/eiv/feature-departures-list').then((m) => m.FeatureDeparturesListModule),
    },
    {
        path: RoutesNames.DebugPickDisplay,
        component: DebugPickDisplayPageComponent,
    },
    {
        path: RoutesNames.HealthCheck,
        component: HealthCheckPageComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
