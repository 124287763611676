import { Departure } from '../models/data.model';
import { isOutdated } from '@traas/common/utils';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DepartureCacheService {
    #data?: Departure[];

    get data(): Departure[] | undefined {
        return this.#computeCacheDepartures();
    }

    set data(value: Departure[]) {
        this.#data = value;
    }

    #computeCacheDepartures(): Departure[] | undefined {
        if (!this.#data) {
            return undefined;
        }

        const updatedData = this.#data
            .map((departure) => {
                return {
                    ...departure,
                    isGone: isOutdated(departure.plannedDepartureTime, departure.departureDelay),
                };
            })
            .filter((departure) => !departure.isGone);

        if (this.#data.length > 0 && updatedData.length === 0) {
            return undefined;
        }
        return updatedData;
    }
}
