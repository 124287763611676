<div class="container">
    <div class="title-container">
        <div class="icon-container">
            <ion-icon src="/assets/icons/warning-variant-1.svg"></ion-icon>
        </div>
        <h1 class="title">Erreur de configuration</h1>
    </div>
    <div class="error-container">
        <div class="error-message">Il y a une erreur de configuration. Veuillez corriger le problème pour continuer.</div>
        <br />
        <div class="error-message">
            <p>
                Code d'erreur: {{ errorCode }}
            </p>
            <br/>
            @if (errorDetail) {
                <p>
                    Détail: {{ errorDetail }}
                </p>
            }
        </div>
    </div>
</div>
