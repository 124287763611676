<ion-header>
    <ion-title>Health check EIV</ion-title>
</ion-header>

<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col size-md="6">
                <eiv-datatable title="Manifest" [data]="manifestJson | async" />
            </ion-col>
            <ion-col size-md="6">
                <ng-container>
                    <p>Synthèse endpoints</p>
                    <br />
                    <ion-item-group>
                        <ion-item>
                            <ion-label>Display config</ion-label>
                            @if(displayConfigEndpointStatus | async; as status) {
                                <ion-note slot="end">{{ status }}</ion-note>
                            } @else {
                                <ion-spinner />
                            }
                        </ion-item>
                        <ion-item>
                            <ion-label>Departures</ion-label>
                            @if(syntheseDepartureEndpointStatus | async; as status) {
                                <ion-note slot="end">{{ status }}</ion-note>
                            } @else {
                                <ion-spinner />
                            }
                        </ion-item>
                        <ion-item>
                            <ion-label>Disruption messages</ion-label>
                            @if(syntheseDisruptionMessageEndpointStatus | async; as status) {
                                <ion-note slot="end">{{ status }}</ion-note>
                            } @else {
                                <ion-spinner />
                            }
                        </ion-item>
                    </ion-item-group>
                </ng-container>
            </ion-col>
        </ion-row>
    </ion-grid>

    <eiv-datatable title="Display configuration" [data]="$displayConfig | async" />
</ion-content>
