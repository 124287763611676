import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigService, FakeDeparturesConfig, RoutingService } from '@traas/eiv/common';

@Component({
    selector: 'eiv-debug-pick-display-page',
    templateUrl: './debug-pick-display-page.component.html',
    styleUrls: ['./debug-pick-display-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugPickDisplayPageComponent {
    $screenDefinitionIsWrong = new BehaviorSubject<boolean>(false);

    @HostListener('window:resize', ['$event'])
    onResize(event?: any): void {
        this.$screenDefinitionIsWrong.next(this.#isScreenSizeValid());
    }

    constructor(
        private readonly routingService: RoutingService,
        private readonly configService: ConfigService,
    ) {
        this.$screenDefinitionIsWrong.next(this.#isScreenSizeValid());
    }

    redirectToMockedDeparturesList(): void {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        this.configService.setDebug(
            'assets/mock/departures-list-config.json',
            this.#createFakeDeparturesConfig({ nbDepartures: 30, nbDeparturesInPast: 0, nbDeparturesCanceled: 0 }),
        );
    }

    redirectToMockedMapDepartures(): void {
        this.configService.setDebug(
            'assets/mock/map-departures-config.json',
            this.#createFakeDeparturesConfig({ nbDepartures: 30, nbDeparturesInPast: 0, nbDeparturesCanceled: 0 }),
        );
    }

    redirectToMockedDeparturesListWithExpandedDepartures(): void {
        this.configService.setDebug(
            'assets/mock/expanded-departures-list-config.json',
            this.#createFakeDeparturesConfig({ nbDepartures: 30, nbDeparturesInPast: 0, nbDeparturesCanceled: 0 }),
        );
    }

    redirectToMockedPlatformDeparture(): void {
        this.configService.setDebug(
            'assets/mock/platform-departure-config.json',
            this.#createFakeDeparturesConfig({ nbDepartures: 30, nbDeparturesInPast: 0, nbDeparturesCanceled: 0, onDemand: true }),
        );
    }

    redirectToDeparturesListWithConfig(nbDepartures: number, nbDeparturesInPast: number, nbDeparturesCanceled: number): void {
        this.configService.setDebug(
            'assets/mock/departures-list-config.json',
            this.#createFakeDeparturesConfig({ nbDepartures, nbDeparturesInPast, nbDeparturesCanceled }),
        );
    }

    redirectToMockedConfigurationError(): void {
        this.routingService.redirectToConfigurationErrorPage();
    }

    #isScreenSizeValid(): boolean {
        if (window.innerWidth === 1920 && window.innerHeight === 1080) {
            return false;
        }
        if (window.innerWidth === 1080 && window.innerHeight === 1920) {
            return false;
        }
        return true;
    }

    #createFakeDeparturesConfig(options: {
        nbDepartures: number;
        nbDeparturesInPast: number;
        nbDeparturesCanceled: number;
        onDemand?: boolean;
    }): FakeDeparturesConfig {
        return {
            nbDepartures: options.nbDepartures,
            nbDeparturesInPast: options.nbDeparturesInPast,
            nbDeparturesCanceled: options.nbDeparturesCanceled,
            onDemand: options.onDemand,
        };
    }
}
